import React from "react";
import Seo from "../../components/seo";
import Layout from "../../components/Layout";
import HeroSection from "../../components/Services/HeroSection";
import FrequentlyAskQuestionSection from "../../components/Services/FrequentlyAskQuestionSection";
import OurPartnersSection from "../../components/Home/OurPartnersSection";
import RemoteEngineeringSection from "../../components/Services/RemoteEngineering";
import get from "lodash/get";
import {graphql} from "gatsby";

const Services = ({ data, location }) => {
    console.log(data);
    const pageData = get(data, "prismicServices.dataRaw", {});
    const pageData_1 = get(data, "prismicOurPatnersSection.dataRaw", {});

    const partners = {
        carousal_title: get(pageData_1, "carousal_title", []),
        carousal_description: get(pageData_1, "carousal_description", []),
        our_partners_carousal: get(pageData_1, "our_partners_carousal", ""),
    }

    const hero = {
        hero_button_label: get(pageData, "hero_button_label", []),
        hero_description: get(pageData, "hero_description", []),
        hero_image: get(pageData, "hero_image.url", ""),
        hero_title: get(pageData, "hero_title", ""),
    }

    const remote = {
        button_label: get(pageData, "button_label", []),
        cards: get(pageData, "cards", []),
        cards_heading: get(pageData, "cards_heading", ""),
        bottom_text: get(pageData, "bottom_text", ""),
    }

    const faq = {
        faq_caption: get(pageData, "faq_caption", ""),
        faq_title: get(pageData, "faq_title", ""),
        faq_description: get(pageData, "faq_description", []),
        faq: get(pageData, "faq", []),
        person_a: get(pageData, "person_a.url", ""),
        person_b: get(pageData, "person_b.url", ""),
        person_c: get(pageData, "person_c.url", ""),
        bottom_description: get(pageData, "bottom_description", ""),
        bottom_title: get(pageData, "bottom_title", ""),
        button_text: get(pageData, "button_text", ""),
    }

  return (
    <Layout location={location} title={""}>
      <HeroSection data={hero}/>
      <RemoteEngineeringSection data={remote}/>
      <OurPartnersSection data={partners}/>
      <FrequentlyAskQuestionSection data={faq}/>
    </Layout>
  );
};

export const Head = ({ data }) => {
  return <Seo title={"Extended Teams"} description={"excerpt"} />;
};
export default Services;

export const query = graphql`
  query ServicePage($uid: String) {
    prismicServices(uid: { eq: $uid }) {
      uid
      dataRaw
    }
    prismicOurPatnersSection{
      dataRaw
    }
  }
`;

