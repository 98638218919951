// extracted by mini-css-extract-plugin
export var frequently_question_section = "index-module--frequently_question_section--44b7c";
export var frequently_question_section_area = "index-module--frequently_question_section_area--847fe";
export var frequently_question_section_area_faqs = "index-module--frequently_question_section_area_faqs--87a29";
export var frequently_question_section_area_sub = "index-module--frequently_question_section_area_sub--0ca29";
export var frequently_question_section_area_title = "index-module--frequently_question_section_area_title--f5048";
export var frequently_question_still_question_section = "index-module--frequently_question_still_question_section--cf847";
export var frequently_question_still_question_section_button = "index-module--frequently_question_still_question_section_button--fe16f";
export var frequently_question_still_question_section_button_arrow = "index-module--frequently_question_still_question_section_button_arrow--12af1";
export var frequently_question_still_question_section_button_call = "index-module--frequently_question_still_question_section_button_call--4b2c0";
export var frequently_question_still_question_section_img = "index-module--frequently_question_still_question_section_img--e26bd";
export var frequently_question_still_question_section_img_list = "index-module--frequently_question_still_question_section_img_list--a8b24";
export var frequently_question_still_question_section_para = "index-module--frequently_question_still_question_section_para--c8fa7";
export var frequently_question_still_question_section_title = "index-module--frequently_question_still_question_section_title--a1596";