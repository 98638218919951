// extracted by mini-css-extract-plugin
export var card_section_container = "index-module--card_section_container--cf440";
export var com_cel = "index-module--com_cel--75208";
export var com_col = "index-module--com_col--981e7";
export var remote_engineering_button = "index-module--remote_engineering_button--728c5";
export var remote_engineering_last_parm = "index-module--remote_engineering_last_parm--89df4";
export var remote_engineering_section = "index-module--remote_engineering_section--61217";
export var remote_engineering_section_card = "index-module--remote_engineering_section_card--0b017";
export var remote_engineering_section_card_1 = "index-module--remote_engineering_section_card_1--623f8";
export var remote_engineering_section_card_2 = "index-module--remote_engineering_section_card_2--19d7d";
export var remote_engineering_section_card_3 = "index-module--remote_engineering_section_card_3--e3fb1";
export var remote_engineering_section_card_img = "index-module--remote_engineering_section_card_img--400fd";
export var remote_engineering_section_card_list = "index-module--remote_engineering_section_card_list--173c0";
export var remote_engineering_section_card_section = "index-module--remote_engineering_section_card_section--e82e1";
export var remote_engineering_section_card_title = "index-module--remote_engineering_section_card_title--a2623";
export var remote_engineering_section_title = "index-module--remote_engineering_section_title--7244f";