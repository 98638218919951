import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Button from "react-bootstrap/Button";
import * as re from "./index.module.scss";
import {PrismicRichText} from "@prismicio/react";
import next3zeros_hire_developers_button_arrow
    from "../../../images/co_developing/co_developing_product/next3zeros_hire_developers_button_arrow.png";

const RemoteEngineeringSection = ({data}) => {

    const card_data = data.remote_engineering_card;

    function handleClick(){
        window.location = '/contact-us'
    }

    return (
        <section className={`start_co_developing_section ${re.remote_engineering_section}`}>
            <Container className={re.card_section_container}>
                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        {/*Title*/}
                        <div className={re.remote_engineering_section_title}>
                            {data.cards_heading}
                        </div>
                    </Col>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className={re.com_cel}>
                        <div>
                            <Row className={re.remote_engineering_section_card_section}>
                                {
                                    data.cards.map((item, idx) => {
                                        return (
                                            <Col xxxl={4} xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} xxs={12}
                                                 className={re.com_col} key={idx}>
                                                <Card className={re.remote_engineering_section_card}>
                                                    <Card.Body>
                                                        <Card.Text>

                                                            {/*Card Icon*/}
                                                            <img className={re.remote_engineering_section_card_img}
                                                                 src={item.icon.url}
                                                            />

                                                            {/*Card Title*/}
                                                            <div className={re.remote_engineering_section_card_title}>
                                                                <h5>{item.title}</h5>
                                                            </div>

                                                            {/*Card List*/}
                                                            <div className={re.remote_engineering_section_card_list}>
                                                                <PrismicRichText field={item.body}/>
                                                            </div>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </Col>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        {/*Button Text*/}
                        <Button className={re.remote_engineering_button}
                                type="button"
                                variant="primary"
                                onClick={handleClick}
                        >
                            {data.button_label}
                            <img src={next3zeros_hire_developers_button_arrow}/>
                        </Button>

                        {/*Bottom Description*/}
                        <p className={re.remote_engineering_last_parm}>
                            {data.bottom_text}
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};


export default RemoteEngineeringSection;